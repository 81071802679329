class GoogleMap {
  static loadGoogleMapsApi(libraries) {
    const loadGoogleMapsApi = require('./load-google-maps-api')
    return loadGoogleMapsApi({
      key: process.env.GOOGLE_MAPS_KEY,
      libraries,
      map_ids: '61fc38ed81390df6',
    })
  }
}

export { GoogleMap }
