import { GoogleMap } from './google-map'
import { DOMReady } from './lib/domready'

DOMReady(main)

let inputInstances = {}

export function getAddress(components, place) {
  const streetNumber = components.find((c) => c.types[0] === 'street_number')
  const route = components.find((c) => c.types[0] === 'route')

  const address = [
    streetNumber ? streetNumber.short_name : '',
    route ? route.short_name : '',
  ]

  const formattedAdress = address.join(' ').trim()

  return formattedAdress ? formattedAdress : place.name
}

export function getAddressComplement(components) {
  return ''
}

export function getZipcode(components) {
  const postalCode = components.find((c) => c.types[0] === 'postal_code')

  return postalCode ? postalCode.short_name : ''
}

export function getCity(components) {
  const locality = components.find((c) => c.types[0] === 'locality')
  const postalTown = components.find((c) => c.types[0] === 'postal_town')

  const city = [
    locality ? locality.short_name : '',
    postalTown ? postalTown.short_name : '',
  ]

  return city.join(' ').trim()
}

export function getArea2(components) {
  const area2 = components.find(
    (c) => c.types[0] === 'administrative_area_level_2'
  )

  return area2 ? area2.short_name : ''
}

export function getArea1(components) {
  const area1 = components.find(
    (c) => c.types[0] === 'administrative_area_level_1'
  )

  return area1 ? area1.short_name : ''
}

export function getCountry(components) {
  const country = components.find((c) => c.types[0] === 'country')

  return country ? country.short_name : ''
}

export function getCountryName(components) {
  const country = components.find((c) => c.types[0] === 'country')

  return country ? country.long_name : ''
}

function initInput(input) {
  const address = document.querySelector(input.dataset.addressTarget)
  const addressComplement = document.querySelector(
    input.dataset.addressComplementTarget
  )
  const coordsX = document.querySelector(input.dataset.coordsXTarget)
  const coordsY = document.querySelector(input.dataset.coordsYTarget)
  const zipcode = document.querySelector(input.dataset.zipcodeTarget)
  const name = document.querySelector(input.dataset.nameTarget)
  const city = document.querySelector(input.dataset.cityTarget)
  const country = document.querySelector(input.dataset.countryTarget)
  const placeNotFound = document.querySelector(
    input.dataset.placeNotFoundTarget
  )

  let shouldDisplayPlaceNotFound = false

  function placeChanged() {
    const place = this.getPlace()

    if (name) {
      name.value = place.name ? place.name : ''
    }
    if (address) {
      address.value = place.address_components
        ? getAddress(place.address_components, place)
        : ''
      if (
        (address.required || address.dataset.requiredToggle) &&
        !address.value
      ) {
        shouldDisplayPlaceNotFound = true
      }
    }
    if (addressComplement) {
      addressComplement.value = place.address_components
        ? getAddressComplement(place.address_components)
        : ''
      if (
        (addressComplement.required ||
          addressComplement.dataset.requiredToggle) &&
        !addressComplement.value
      ) {
        shouldDisplayPlaceNotFound = true
      }
    }
    if (coordsX && place.geometry && place.geometry.location) {
      coordsX.value = place.geometry.location.lng()
    }
    if (coordsY && place.geometry && place.geometry.location) {
      coordsY.value = place.geometry.location.lat()
    }
    if (zipcode) {
      zipcode.value = place.address_components
        ? getZipcode(place.address_components)
        : ''
      if (
        (zipcode.required || zipcode.dataset.requiredToggle) &&
        !zipcode.value
      ) {
        shouldDisplayPlaceNotFound = true
      }
    }
    if (city) {
      city.value = place.address_components
        ? getCity(place.address_components)
        : ''
      if ((city.required || city.dataset.requiredToggle) && !city.value) {
        shouldDisplayPlaceNotFound = true
      }
    }
    if (country) {
      country.value = place.address_components
        ? getCountry(place.address_components)
        : ''
      if (
        (country.required || country.dataset.requiredToggle) &&
        !country.value
      ) {
        shouldDisplayPlaceNotFound = true
      }
    }

    if (placeNotFound) {
      const placeTarget = document.querySelector(
        placeNotFound.dataset.displayChecked
      )
      if (placeTarget) {
        if (shouldDisplayPlaceNotFound) {
          placeNotFound.checked = 'checked'
          placeTarget.classList.remove('hidden')
        } else {
          placeNotFound.checked = false
          placeTarget.classList.add('hidden')
        }
      }
    }
  }

  try {
    google.maps.event.removeListener(placeChanged)
    google.maps.event.clearInstanceListeners(inputInstances[input.id])
  } catch (ex) {}
  inputInstances[input.id] = new google.maps.places.Autocomplete(input)
  google.maps.event.addListener(
    inputInstances[input.id],
    'place_changed',
    placeChanged
  )
}

function refreshBadge(boxBadge, allPlaces, multipleHiddenInput) {
  boxBadge.innerHTML = ''
  allPlaces.forEach((place) => {
    let labels = Object.keys(place).map(function (key, index) {
      return key === 'name' ? place[key] : ''
    })
    labels = labels.filter((value) => value !== '')
    labels = [...new Set(labels)]
    let label = labels.join(', ').trim()
    boxBadge.insertAdjacentHTML(
      'beforeend',
      '<span class="badge bg-primary d-inline-flex justify-content-start align-items-center py-2 px-var mb-2 mr-2" style="--ui-px: 10px;"><span class="badge-label">' +
        label +
        '</span><span class="badge-delete-button cursor-pointer" data-item-target="' +
        place.name +
        '"> <svg class="ml-2" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"></path> <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"></path> </svg> </span> </span>'
    )
  })

  const badges = boxBadge.querySelectorAll('.badge')
  badges.forEach((badge) => {
    badge
      .querySelector('span[data-item-target]')
      .addEventListener('click', (evt) => {
        const value = badge.querySelector('span[data-item-target]').dataset
          .itemTarget
        allPlaces = allPlaces.filter(function (obj) {
          return obj.name !== value
        })
        multipleHiddenInput.value = JSON.stringify(allPlaces)
        refreshBadge(boxBadge, allPlaces, multipleHiddenInput)
      })
  })
}

function initMultipleInput(inputMultiple) {
  const multipleHiddenInput = document.querySelector(
    inputMultiple.dataset.multipleHiddenTarget
  )

  let allPlaces = JSON.parse(multipleHiddenInput.value)

  const boxBadge = document.querySelector('#box_badge_' + inputMultiple.id)

  refreshBadge(boxBadge, allPlaces, multipleHiddenInput)

  function placeChanged() {
    const place = this.getPlace()

    let onePlace = {
      name: place.name,
      address: place.address_components
        ? getAddress(place.address_components, place)
        : '',
      city: place.address_components ? getCity(place.address_components) : '',
      zipcode: place.address_components
        ? getZipcode(place.address_components)
        : '',
      country: place.address_components
        ? getCountry(place.address_components)
        : '',
      coordsX:
        place.geometry && place.geometry.location
          ? place.geometry.location.lng()
          : '',
      coordsY:
        place.geometry && place.geometry.location
          ? place.geometry.location.lat()
          : '',
    }

    allPlaces.push(onePlace)
    allPlaces = allPlaces.filter(
      (place, index, self) =>
        self.findIndex((p) => p.name === place.name) === index
    )

    multipleHiddenInput.value = JSON.stringify(allPlaces)
    inputMultiple.value = ''
    refreshBadge(boxBadge, allPlaces, multipleHiddenInput)
    inputMultiple.focus()
  }

  try {
    google.maps.event.removeListener(placeChanged)
    google.maps.event.clearInstanceListeners(inputInstances[inputMultiple.id])
  } catch (ex) {}

  inputInstances[inputMultiple.id] = new google.maps.places.Autocomplete(
    inputMultiple /*,
      {
        types: ['(regions)'],
      }*/
  )
  /*inputInstances[inputMultiple.id].setFields(['address_components', 'name', 'geometry'])*/

  google.maps.event.addListener(
    inputInstances[inputMultiple.id],
    'place_changed',
    placeChanged
  )
}

export function main() {
  const placesAutocompleteInputs = document.querySelectorAll(
    '[data-places-autocomplete]'
  )
  if (placesAutocompleteInputs.length > 0) {
    GoogleMap.loadGoogleMapsApi(['places']).then(function (googleMaps) {
      for (let input of placesAutocompleteInputs) {
        initInput(input)

        let inputValue = null
        setInterval(function () {
          if (input.dataset.waiting) {
            return
          }
          if (
            inputValue !== null &&
            inputValue != input.value &&
            inputValue == ''
          ) {
            initInput(input)
            setTimeout(function () {
              input.dispatchEvent(new InputEvent('focus'))
            }, 10)
          }
          inputValue = input.value
        }, 50)
      }
    })
  }

  const multiplePlacesAutocompleteInputs = document.querySelectorAll(
    '[data-multiple-places-autocomplete]'
  )
  if (multiplePlacesAutocompleteInputs.length > 0) {
    GoogleMap.loadGoogleMapsApi(['places']).then(function (googleMaps) {
      for (let inputMultiple of multiplePlacesAutocompleteInputs) {
        initMultipleInput(inputMultiple)

        let inputValue = null
        setInterval(function () {
          if (inputMultiple.dataset.waiting) {
            return
          }
          if (
            inputValue !== null &&
            inputValue != inputMultiple.value &&
            inputValue == ''
          ) {
            initMultipleInput(inputMultiple)
            setTimeout(function () {
              inputMultiple.dispatchEvent(new InputEvent('focus'))
            }, 10)
          }
          inputValue = inputMultiple.value
        }, 50)
      }
    })
  }
}
