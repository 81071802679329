function isDatalayerLoaded() {
  window.dataLayer = window.dataLayer || []
  const startEvent = window.dataLayer.find(e => e['gtm.start'])

  // No start event: GTM is not loaded
  if (!startEvent) {
    return false
  }

  // Inline GTM ran, but main GTM has not (env-dev, network issue or content blocker): GTM is not loaded
  if (!startEvent['gtm.uniqueEventId']) {
    return false
  }

  return true
}

export function datalayerPush(event, data = {}, eventCallback = () => {}) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event,
    eventCallback,
    eventTimeout: 1000,
    ...data,
  })

  const body = document.querySelector('body')
  if (body && (body.classList.contains('env-dev') || body.classList.contains('env-preprod'))) {
    console.log('[debug] dataLayer event pushed: ', event, data)
  }

  if (!isDatalayerLoaded()) {
    eventCallback()
  }
}
