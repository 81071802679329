import { marked } from 'marked'

let Translator = {
  ready: false,
  translations: {},
  fetchTranslations() {
    return new Promise((resolve, reject) => {
      fetch(window.urls.translations, {
        headers: {
          Accept: 'application/json',
        },
      }).then((response) => {
        if (Math.floor(response.status / 100) === 2) {
          response.json().then((result) => {
            this.ready = true
            this.translations = result
            resolve(this)
          })
        } else {
          reject('Unable to fetch translations')
        }
      })
    })
  },

  trans(key, params = {}) {
    if (this.translations[key] !== undefined) {
      let text = this.translations[key]
      // only supports basic substitutions, not as advanced at the backend's system yet (a third-party library might exist?)
      for (const param of Object.entries(params)) {
        text = text.replace(new RegExp(`{${param[0]}}`, 'g'), param[1])
      }
      if (text && text.startsWith('markdown:')) {
        const isMultiline = text.includes('\n')
        text = marked.parse(text.slice('markdown:'.length)).trim()
        text = text
          .replaceAll('<em>', '<span class="emphasis">')
          .replaceAll('</em>', '</span>')
        if (isMultiline) {
          text = text
            .replaceAll('\n\n', '<br />') // should not happen with this parser?
            .replaceAll('<ul>\n', '<ul>')
            .replaceAll('</li>\n', '</li>')
          text = `<div class="markdown text-preline">${text}</div>`
        } else {
          text = text
            .replaceAll('</p>\n<p>', '\n')
            .replaceAll('<p>', '')
            .replaceAll('</p>', '')
        }
      }
      return text
    }
    console.warn(`Translations key ${key} not found`)

    return key
  },
  hasTranslation(key) {
    return this.translations[key] !== undefined
  },
}

export default Translator.fetchTranslations()
